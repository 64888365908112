import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { environment } from "../environments/environment";

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "clearline-prepaid-portal";
  baseName = environment.baseName;
}
